@import url(https://fonts.googleapis.com/css2?family=Lato&family=Nunito&family=Open+Sans&family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainIcon {
	color: inherit;
	width: 32px;
	padding: 5px;
}
.logo {
	display: flex;
	align-items: center;
}

.toolBar  {
	display: flex;
	justify-content: space-between;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}


@media (max-width: 600px) {
	.item {
	  display: none;
	}
	/* .itemHorizontal {
	  display: block;	
	} */
      }

/* .itemHorizontal {
	display: none;
} */
      @media (min-width: 600px) {
	.itemHorizontal {
	  display: none;	
	}
      }
.table {
	min-width: clamp(20rem, 58.5vw, 700px);
}
.table {
	min-width: clamp(20rem, 58.5vw, 700px);
}
